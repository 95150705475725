import React, { useState, useEffect } from "react";
import Nav from "./Navigation/Nav.js";
import { useNavigate } from "react-router-dom";
import Products from "./Products/Products.js";
import Sidebar from "./Sidebar/Sidebar.js";
import Card from "./components/Card.js";
import Recommended from "./Recommended/Recommended.js";
import "./index.css";
import Footer from "./footer/Footer.js";
import initSqlJs from "sql.js/dist/sql-wasm.js";
import Loading from "./loading/Loading.js";
import PdfHeader from "./pdf/PdfHeader.js";

function App() {
  const initialFilters = {
    category: [],
    size: [],
    colors: [],
    location: [],
  };

  const [selectedFilters, setSelectedFilters] = useState(initialFilters);

  let [globalQuery, setglobalQuery] = useState(
    "SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM carpet_info WHERE Category IS NOT NULL AND Category <> '' ORDER BY CAST(Roll_Number as int) DESC"
  );
  const queryLocation = "SELECT DISTINCT Location FROM carpet_info";
  const getCurrentPageFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const page = searchParams.get("page");
  
    if (page === "MQ%3D%3D" || page === "TmFO") {
      // No 'page' parameter found in URL
      window.location.reload();
      return 1; // Default to 1 if no 'page' is found
    }
  
    try {
      // Base64 decode first
      const base64DecodedPage = atob(page);
      // console.log("Base64 Decoded Page:", base64DecodedPage);
  
      // Convert to integer
      const pageNumber = parseInt(base64DecodedPage, 10);
  
      // Check if the result is NaN
      if (isNaN(pageNumber)) {
        return 1; // Fallback to 1 if page is NaN
        
      }
     
      return pageNumber;
    } catch (error) {
      console.error("Error decoding page:", error);
      return 1; // Fallback if something goes wrong
    }
  };
  

  // Initialize filters from local storage
  const getInitialFiltersApplied = () => {
    return JSON.parse(localStorage.getItem("filtersApplied")) || false;
  };
  let maxval;
  const [searchQuery, setSearchQuery] = useState(
    localStorage.getItem("searchQuery") || ""
  );
  const [expanded, setExpanded] = useState(false);
  const [isPdfHeaderVisible, setIsPdfHeaderVisible] = useState(false);
  const [isCopyingLink, setIsCopyingLink] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [currentPage, setCurrentPage] = useState(getCurrentPageFromURL());
  const [dbMinPrice, setdbMinPrice] = useState(0);
  const [dbMaxPrice, setdbMaxPrice] = useState(0);
  const [minPrice, setMinPrice] = useState(
     parseInt(localStorage.getItem("minPrice")) || 0
  );
  const [maxPrice, setMaxPrice] = useState(
    parseInt(localStorage.getItem("maxPrice")) || dbMaxPrice
  );
  const [newOffset, setNewOffset] = useState(0);
  const [addLocation, setAddLocation] = useState([]); // to add new location from DB
  const [loading, setLoading] = useState(false);
  const [db, setDb] = useState([]);
  const [queryResult, setqueryResult] = useState([]);
  const [dbInitialized, setDbInitialized] = useState(false);
  const [carpetsPerPage] = useState(60);
  const [totalCount, setTotalCount] = useState(0);
  const [filtersApplied, setFiltersApplied] = useState(
    getInitialFiltersApplied()
  );
  const queryParams = new URLSearchParams(window.location.search);
  const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const [selectedCards, setSelectedCards] = useState([]);
  const navigate = useNavigate();

  const toggleSelectCard = (card) => {
    setSelectedCards((prevSelectedCards) => {
      const newSelectedCards = prevSelectedCards.includes(card)
        ? prevSelectedCards.filter((c) => c !== card)
        : [...prevSelectedCards, card];
      console.log(newSelectedCards);
      return newSelectedCards;
    });
  };

  const transferCards = () => {
    navigate("/pdf", { state: { selectedCards } });
  };

  //sqlite query
  const wasmFile =
    "https://cdnjs.cloudflare.com/ajax/libs/sql.js/1.10.3/sql-wasm.wasm";
  const sqlPromise = initSqlJs({ locateFile: () => wasmFile });
  const dataPromise = fetch(
    `${process.env.REACT_APP_CF}/carpet_kingdom.db`
  ).then((res) => res.arrayBuffer());

  useEffect(() => {
    // console.log("-- in init --");
    async function initializeDatabase() {
      if (!dbInitialized) {
        try {
          // SELECT MAX(CAST(Price AS INT )) FROM 'carpet_info'
          const [SQL, buf] = await Promise.all([sqlPromise, dataPromise]);
          const db = new SQL.Database(new Uint8Array(buf));
          const offset = (currentPage - 1) * carpetsPerPage;
          let tmp = ` LIMIT ${carpetsPerPage} OFFSET ${offset}`;
          let tmp2 = globalQuery;
          tmp2 += tmp;
          const stmt = db.exec(tmp2);

          if (stmt.length === 0) {
            setLoading(true);
          } else {
            const result = stmt[0].values;
            setLoading(false);
            // console.log(result);

            const formattedData = result.map((item, index) => (
              <Card
                key={item[0]}
                img={item[9]}
                title={item[0]}
                color={item[10].split(", ")[0]}
                colors={item[3]
                  .split("#")
                  .slice(1)
                  .map((i) => "#" + i)}
                size={item[2]}
                category={item[1]}
                length={item[4]}
                breadth={item[5]}
                price={item[6]}
                location={item[7]}
                gst={item[8]}
                isSelected={selectedCards.includes(item[0])}
                toggleSelectCard={toggleSelectCard}
                interactive={true}
                updatedAt={item[11]}
              />
            ));
            setDbInitialized(true);
            setDb(db);
            setqueryResult(formattedData);
            // TODO: should this change to result.length ?
            const countStmt = db.exec(
              `SELECT COUNT(*) AS total FROM carpet_info WHERE Category IS NOT NULL AND Category <> ''`
            );
            const totalCount = countStmt[0].values[0][0];
            setTotalCount(totalCount);

            const maxStmt = db.exec(
              "SELECT MAX(CAST(Price AS INT)) FROM 'carpet_info'"
            );
            maxval = maxStmt[0].values[0][0];
            // setMaxPrice(maxval);
            setdbMaxPrice(maxval);

            if (maxPrice === 0) {
              setMaxPrice(maxval);
            }
            const minStmt = db.exec(
              "SELECT MIN(CAST(Price AS INT)) FROM 'carpet_info'"
            );
            let minval = minStmt[0].values[0][0];
            // console.log(minval);
            setdbMinPrice(minval);
          }
        } catch (err) {
          console.log(err);
        }

      } else {
        // console.log("Database already initialized.");
      }
    }

    initializeDatabase();
  }, [
    dbInitialized,
    currentPage,
    carpetsPerPage,
    dataPromise,
    sqlPromise,
    globalQuery,
    selectedCards,
  ]);


  useEffect(() => {
    // Store filtersApplied in local storage whenever it changes
    localStorage.setItem("filtersApplied", JSON.stringify(filtersApplied));
    localStorage.setItem("searchQuery", searchQuery);
    localStorage.setItem("minPrice", minPrice);
    localStorage.setItem("maxPrice", maxPrice);
  }, [filtersApplied, searchQuery, maxPrice, minPrice]);

  // Function to handle page change
  const handlePageChange = (page) => {
    // console.log("-- in page change --");

    const offset = (page - 1) * carpetsPerPage;
    setNewOffset(offset);
    // console.log("offset ", offset);
    setCurrentPage(page);
    performSearch(page);
    window.scrollTo({ top: 0, behavior: "smooth" });

    // console.log("current page:", page);
    let tmp = ` LIMIT ${carpetsPerPage} OFFSET ${offset}`;
    let tmp2 = globalQuery;
    tmp2 += tmp;
    // console.log("tmp2 ", tmp2);
    const stmt = db.exec(tmp2);
    if (stmt.length === 0) {
      setLoading(true);
    } else {
      const result = stmt[0].values;
      setLoading(false);
      const newData = result.map((item, index) => (
        <Card
          key={item[0]}
          img={item[9]}
          title={item[0]}
          color={item[10].split(", ")[0]}
          colors={item[3]
            .split("#")
            .slice(1)
            .map((i) => "#" + i)}
          size={item[2]}
          category={item[1]}
          length={item[4]}
          breadth={item[5]}
          price={item[6]}
          location={item[7]}
          gst={item[8]}
          isSelected={selectedCards.includes(item[0])}
          toggleSelectCard={toggleSelectCard}
          interactive={true}
          updatedAt={item[11]}
        />
      ));
      setqueryResult(newData);
    }
  };

  useEffect(() => {
    const decodeQueryParam = (param) => {
      return param ? atob(param) : null; // Check if param exists before decoding
    };

    // Get individual query parameters and update selectedFilters state accordingly
    const categoryParam = decodeQueryParam(queryParams.get("category"));
    const sizeParam = decodeQueryParam(queryParams.get("size"));
    const colorsParam = decodeQueryParam(queryParams.get("colors"));
    const locationParam = decodeQueryParam(queryParams.get("location"));
    const minPriceParam = decodeQueryParam(queryParams.get("minPrice"));
    const maxPriceParam = decodeQueryParam(queryParams.get("maxPrice"));
    const linkCopiedParam = decodeQueryParam(queryParams.get("linkCopied"));

    setSelectedFilters({
      category: categoryParam ? categoryParam.split(",") : [],
      size: sizeParam ? sizeParam.split(",") : [],
      colors: colorsParam ? colorsParam.split(",") : [],
      location: locationParam ? locationParam.split(",") : [],
    });


    setMinPrice(minPriceParam !== null ? minPriceParam : dbMinPrice);
    setMaxPrice(maxPriceParam !== null ? maxPriceParam : dbMaxPrice);

    if (linkCopiedParam === true) {
      // Set navbar and PDF header visibility based on URL parameters
      const decodedNavbarState = atob(queryParams.get("mainnav"));
      const decodedPdfHeaderState = atob(queryParams.get("nav"));

      setIsNavbarVisible(decodedNavbarState === "false");
      setIsPdfHeaderVisible(decodedPdfHeaderState === "true");
   
    } else {
      const decodedNavbarState = atob(queryParams.get("mainnav"));
      const decodedPdfHeaderState = atob(queryParams.get("nav"));

      setIsNavbarVisible(decodedNavbarState === "true");
      setIsPdfHeaderVisible(decodedPdfHeaderState === "true");
    }
  }, []);

  // filters
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const isArray = Array.isArray(selectedFilters[name]);

    // from claude
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [name]: isArray
        ? checked
          ? [...prevFilters[name], value]
          : prevFilters[name].filter((v) => v !== value)
        : value,
    }));

    // setCurrentPage(1);
    setFiltersApplied(true);
    handlePageChange(1);
  };

  useEffect(() => {
    // Store filtersApplied in local storage whenever it changes
    localStorage.setItem("filtersApplied", JSON.stringify(filtersApplied));
  }, [filtersApplied]);

  const clearAllFilters = () => {
    setSelectedFilters(initialFilters);
    setFiltersApplied(false);
    setMinPrice(dbMinPrice);
    setMaxPrice(dbMaxPrice);
    handlePageChange(1); // Reset to the first page
  };

  useEffect(() => {
    if (dbInitialized) {
      let query = "";

      if (searchQuery) {
        query = `SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM carpet_info WHERE (Roll_Number LIKE '${searchQuery}%') AND CAST(Price AS INT) >= ${minPrice} AND CAST(Price AS INT) <= ${maxPrice} AND `;
      } else {
        query = `SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM carpet_info WHERE CAST(Price AS INT) >= ${minPrice} AND CAST(Price AS INT) <= ${maxPrice} AND `;
      }

      let no_filter = true;
      let tmp = "";

      if (selectedFilters["category"].length > 0) {
        no_filter = false;
        tmp += "Category IN (";
        tmp += selectedFilters["category"].map((cat) => `'${cat}'`).join(", ");

        tmp += ") AND ";
        query += tmp;
      }

      tmp = "";
      if (selectedFilters["location"].length > 0) {
        no_filter = false;
        tmp += "Location IN (";

        tmp += selectedFilters["location"].map((loc) => `'${loc}'`).join(", ");

        tmp += ") AND ";
        query += tmp;
      }

      tmp = "";
      if (selectedFilters["size"].length > 0) {
        no_filter = false;
        tmp += "Size IN (";

        tmp += selectedFilters["size"].map((size) => `'${size}'`).join(", ");

        tmp += ") AND ";
        query += tmp;
      }

      tmp = "";
      if (selectedFilters["colors"].length > 0) {
        no_filter = false;
        tmp += "(";
        tmp += selectedFilters["colors"]
          .map((color) => `Colour LIKE '%${color}%'`)
          .join(" OR ");

        tmp += ") AND ";
        query += tmp;
      }

      if (no_filter) {
        if (searchQuery) {
          query = `SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM carpet_info WHERE (Roll_Number LIKE '${searchQuery}%') AND CAST(Price AS INT) >= ${minPrice} AND CAST(Price AS INT) <= ${maxPrice} `;
        } else {
          query = `SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM carpet_info WHERE CAST(Price AS INT) >= ${minPrice} AND CAST(Price AS INT) <= ${maxPrice} `;
        }
      } else {
        // Remove the last AND in filters
        query = query.substring(0, query.lastIndexOf("AND"));
      }

      query += " ORDER BY CAST(Roll_Number as int) DESC";
      setglobalQuery(query);
      const tmp_stmt = db.exec(query);
      if (tmp_stmt.length === 0) {
        setqueryResult(null);
        setLoading(true);
        setglobalQuery(
          "SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM carpet_info WHERE Category IS NOT NULL AND Category <> '' ORDER BY CAST(Roll_Number as int) DESC"
        );
      } else {
        const tmp_result = tmp_stmt[0].values;
        const totalCount = tmp_result.length;
        setTotalCount(totalCount);
        setLoading(false);

        const offset = (currentPage - 1) * carpetsPerPage;
        query += ` LIMIT ${carpetsPerPage} OFFSET ${offset}`;

        const stmt = db.exec(query);
        if (stmt.length === 0) {
          setLoading(true);
          setqueryResult(null);
          setglobalQuery(
            "SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM carpet_info WHERE Category IS NOT NULL AND Category <> '' ORDER BY CAST(Roll_Number as int) DESC"
          );
        } else {
          const result = stmt[0].values;
          setLoading(false);
          const formattedData = result.map((item) => (
            <Card
              key={item[0]}
              img={item[9]}
              title={item[0]}
              color={item[10].split(", ")[0]}
              colors={item[3]
                .split("#")
                .slice(1)
                .map((i) => "#" + i)}
              size={item[2]}
              category={item[1]}
              length={item[4]}
              breadth={item[5]}
              price={item[6]}
              location={item[7]}
              gst={item[8]}
              isSelected={selectedCards.includes(item[0])}
              toggleSelectCard={toggleSelectCard}
              interactive={true}
              updatedAt={item[11]}
            />
          ));
          setqueryResult(formattedData);
        }
      }
    }
  }, [
    selectedFilters,
    carpetsPerPage,
    currentPage,
    db,
    dbInitialized,
    maxPrice,
    minPrice,
    selectedCards,
  ]);

  //fetching the current link
  const handleCopyLink = () => {
    const currentURL = window.location.href
      ? new URL(window.location.href)
      : null; // Get current URL
    if (!currentURL) {
      console.error("Failed to get current URL");
      return;
    }

    currentURL.searchParams.set("mainnav", btoa(true)); // Set navbar visibility state in URL
    currentURL.searchParams.set("nav", btoa(true)); // Set PDF header visibility state in URL
    currentURL.searchParams.set("linkCopied", btoa(true));

    const updatedURL = currentURL.toString(); // Convert URL object back to string

    console.log(updatedURL);

    setIsCopyingLink(true);

    if (!navigator.clipboard) {
      console.error("Failed to access clipboard");
      return;
    }

    navigator.clipboard
      .writeText(updatedURL)
      .then(() => {
        alert("Link copied to clipboard!");
        setIsCopyingLink(false);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        setIsCopyingLink(false);
      });
  };

  // update url
  useEffect(() => {
    const updateURL = () => {
      if (!selectedFilters) return;

      const searchParams = new URLSearchParams();

      Object.keys(selectedFilters).forEach((key) => {
        if (selectedFilters[key] && selectedFilters[key].length > 0) {
          searchParams.set(key, btoa(selectedFilters[key].join(",")));
        }
      });
      searchParams.set("search", btoa(searchQuery));
      searchParams.set("minPrice", btoa(minPrice.toString()));
      searchParams.set("maxPrice", btoa(maxPrice.toString()));
      searchParams.set("page", btoa(currentPage.toString()));

      // Get the current `linkCopied` state from the URL
      const currentURL = new URL(window.location.href);
      const isLinkCopied = currentURL.searchParams.get("linkCopied");

      // Preserve the `linkCopied` state if it exists
      if (isLinkCopied === btoa(true)) {
        searchParams.set("linkCopied", btoa(true));
        searchParams.set("mainnav", btoa(true));
        searchParams.set("nav", btoa(true));
      } else {
        searchParams.set("linkCopied", btoa(false));
        searchParams.set("mainnav", btoa(false));
        searchParams.set("nav", btoa(false));
      }

      const newUrl = `${baseUrl}?${searchParams.toString()}`;

      // Update the browser's URL without reloading the page
      window.history.replaceState(null, "", newUrl);
    };

    updateURL();
  }, [selectedFilters, minPrice, maxPrice, baseUrl, currentPage]);

  // to solve the edge-case of the page being null
  if (minPrice === null) {
    window.location.reload();
  }

  // price filter functions
  const handleMinPriceChange = (value) => {
    // console.log("-- min price change --");
    // setCurrentPage(1);

    const isCategorySelected =
      Array.isArray(selectedFilters.category) &&
      selectedFilters.category.length > 0;
    const isSizeSelected =
      Array.isArray(selectedFilters.size) && selectedFilters.size.length > 0;
    const isColorsSelected =
      Array.isArray(selectedFilters.colors) &&
      selectedFilters.colors.length > 0;
    const isLocationSelected =
      Array.isArray(selectedFilters.location) &&
      selectedFilters.location.length > 0;

    let query = "";
    if (
      isCategorySelected ||
      isSizeSelected ||
      isColorsSelected ||
      isLocationSelected
    ) {
      let tmp = ` CAST(Price AS INT) >= ${value} AND CAST(Price AS INT) <= ${maxPrice}`;
      let tmp2 = globalQuery;

      let priceRangeRegex =
        /CAST\(Price AS INT\) >= [0-9]+ AND CAST\(Price AS INT\) <= [0-9]+/;
      // from mixtral
      if (priceRangeRegex.test(tmp2)) {
        // If the regular expression matches, replace the matched price range with string b
        query = tmp2.replace(priceRangeRegex, tmp);
      } else {
        // If the regular expression does not match, add the price range from string b to string a before the "ORDER BY" clause
        let index = tmp2.indexOf("ORDER BY CAST(Roll_Number as int) DESC");
        query =
          tmp2.slice(0, index) +
          tmp +
          " ORDER BY CAST(Roll_Number as int) DESC";
      }
    } else {
      if (searchQuery) {
        query = `SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM \
              carpet_info WHERE (Roll_Number LIKE '${searchQuery}%') AND CAST(Price AS INT) >= ${value} AND \
              CAST(Price AS INT) <= ${maxPrice}`;
      } else {
        query = `SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM \
              carpet_info WHERE CAST(Price AS INT) >= ${value} AND CAST(Price AS INT) <= ${maxPrice}`;
      }
    }

    setglobalQuery(query);

    // console.log("query ", query);
    // hack
    const tmp_stmt = db.exec(query);
    if (tmp_stmt.length === 0) {
      setqueryResult(null);
      setLoading(true);
      setglobalQuery(
        "SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM\
        carpet_info WHERE Category IS NOT NULL AND Category <> '' ORDER BY CAST(Roll_Number as int) DESC"
      );
    } else {
      const tmp_result = tmp_stmt[0].values;
      const totalCount = tmp_result.length;
      setTotalCount(totalCount);
      setLoading(false);

      const offset = (currentPage - 1) * carpetsPerPage;
      let tmp = ` LIMIT ${carpetsPerPage} OFFSET ${offset}`;
      query += tmp;

      // console.log("min price query ", query);
      const stmt = db.exec(query);

      if (stmt.length === 0) {
        setLoading(true);
        setqueryResult(null);
        setglobalQuery(
          "SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM\
          carpet_info WHERE Category IS NOT NULL AND Category <> '' ORDER BY CAST(Roll_Number as int) DESC"
        );
      } else {
        const result = stmt[0].values;
        setLoading(false);

        const formattedData = result.map((item, index) => (
          <Card
            key={item[0]}
            img={item[9]}
            title={item[0]}
            color={item[10].split(", ")[0]}
            colors={item[3]
              .split("#")
              .slice(1)
              .map((i) => "#" + i)}
            size={item[2]}
            category={item[1]}
            length={item[4]}
            breadth={item[5]}
            price={item[6]}
            location={item[7]}
            gst={item[8]}
            isSelected={selectedCards.includes(item[0])}
            toggleSelectCard={toggleSelectCard}
            interactive={true}
            updatedAt={item[11]}
          />
        ));

        setqueryResult(formattedData);

        setMinPrice(value);
        setFiltersApplied(true);
      }
    }
  };

  const handleMaxPriceChange = (value) => {
    // console.log("-- max price change --");
    // setCurrentPage(1);

    const isCategorySelected =
      Array.isArray(selectedFilters.category) &&
      selectedFilters.category.length > 0;
    const isSizeSelected =
      Array.isArray(selectedFilters.size) && selectedFilters.size.length > 0;
    const isColorsSelected =
      Array.isArray(selectedFilters.colors) &&
      selectedFilters.colors.length > 0;
    const isLocationSelected =
      Array.isArray(selectedFilters.location) &&
      selectedFilters.location.length > 0;

    let query = "";
    if (
      isCategorySelected ||
      isSizeSelected ||
      isColorsSelected ||
      isLocationSelected
    ) {
      let tmp = ` CAST(Price AS INT) >= ${minPrice} AND CAST(Price AS INT) <= ${value} `;
      let tmp2 = globalQuery;

      let priceRangeRegex =
        /CAST\(Price AS INT\) >= [0-9]+ AND CAST\(Price AS INT\) <= [0-9]+/;
      // from mixtral
      if (priceRangeRegex.test(tmp2)) {
        query = tmp2.replace(priceRangeRegex, tmp);
      } else {
        let index = tmp2.indexOf("ORDER BY CAST(Roll_Number as int) DESC");
        query =
          tmp2.slice(0, index) +
          tmp +
          " ORDER BY CAST(Roll_Number as int) DESC";
      }
    } else {
      if (searchQuery) {
        query = `SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM \
              carpet_info WHERE (Roll_Number LIKE '${searchQuery}%') AND CAST(Price AS INT) >= ${minPrice} AND \
              CAST(Price AS INT) <= ${value} `;
      } else {
        query = `SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM \
              carpet_info WHERE CAST(Price AS INT) >= ${minPrice} AND CAST(Price AS INT) <= ${value} `;
      }
    }

    setglobalQuery(query);

    // hack
    const tmp_stmt = db.exec(query);
    if (tmp_stmt.length === 0) {
      setqueryResult(null);
      setLoading(true);
      setglobalQuery(
        "SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM\
        carpet_info WHERE Category IS NOT NULL AND Category <> '' ORDER BY CAST(Roll_Number as int) DESC"
      );
    } else {
      const tmp_result = tmp_stmt[0].values;
      const totalCount = tmp_result.length;
      setTotalCount(totalCount);
      setLoading(false);

      const offset = (currentPage - 1) * carpetsPerPage;
      let tmp = ` LIMIT ${carpetsPerPage} OFFSET ${offset}`;
      query += tmp;

      // console.log("max price query ", query);

      const stmt = db.exec(query);

      if (stmt.length === 0) {
        setLoading(true);
        setglobalQuery(
          "SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM\
        carpet_info WHERE Category IS NOT NULL AND Category <> '' ORDER BY CAST(Roll_Number as int) DESC"
        );
      } else {
        const result = stmt[0].values;
        setLoading(false);

        const formattedData = result.map((item, index) => (
          <Card
            key={item[0]}
            img={item[9]}
            title={item[0]}
            color={item[10].split(", ")[0]}
            colors={item[3]
              .split("#")
              .slice(1)
              .map((i) => "#" + i)}
            size={item[2]}
            category={item[1]}
            length={item[4]}
            breadth={item[5]}
            price={item[6]}
            location={item[7]}
            gst={item[8]}
            isSelected={selectedCards.includes(item[0])}
            toggleSelectCard={toggleSelectCard}
            interactive={true}
            updatedAt={item[11]}
          />
        ));

        setqueryResult(formattedData);
        setMaxPrice(value);
        setFiltersApplied(true);
      }
    }
  };

  // search
  const handleInputChange = () => {
    // console.log("-- in search --");
    window.scrollTo({ top: 0, behavior: "smooth" });

    setCurrentPage(1);
    performSearch(1);
  };

  const performSearch = (page) => {
    const isCategorySelected =
      Array.isArray(selectedFilters.category) &&
      selectedFilters.category.length > 0;
    const isSizeSelected =
      Array.isArray(selectedFilters.size) && selectedFilters.size.length > 0;
    const isColorsSelected =
      Array.isArray(selectedFilters.colors) &&
      selectedFilters.colors.length > 0;
    const isLocationSelected =
      Array.isArray(selectedFilters.location) &&
      selectedFilters.location.length > 0;

    let queryStr = "";
    if (
      isCategorySelected ||
      isSizeSelected ||
      isColorsSelected ||
      isLocationSelected
    ) {
      let tmp = globalQuery;
      let tmp2 = ` AND (Roll_Number LIKE '${searchQuery}%') `;
      let index = tmp.indexOf("ORDER BY CAST(Roll_Number as int) DESC");
      queryStr =
        tmp.slice(0, index) + tmp2 + " ORDER BY CAST(Roll_Number as int) DESC";
    } else {
      queryStr = `SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM \
                 carpet_info WHERE CAST(Price AS INT) >= ${minPrice} AND CAST(Price AS INT) <= ${maxPrice} AND (Roll_Number LIKE '${searchQuery}%') \
                 ORDER BY CAST(Roll_Number as int) DESC`;
    }

    // Hack
    const tmp_stmt = db.exec(queryStr);
    if (tmp_stmt.length === 0) {
      setqueryResult(null);
      setLoading(true);
      setglobalQuery(
        "SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM\
        carpet_info WHERE Category IS NOT NULL AND Category <> '' ORDER BY CAST(Roll_Number as int) DESC"
      );
    } else {
      const tmp_result = tmp_stmt[0].values;
      const totalCount = tmp_result.length;
      setTotalCount(totalCount);
      setLoading(false);

      // Update global query
      setglobalQuery(queryStr);

      // Pagination handling
      const offset = (page - 1) * carpetsPerPage;
      let tmp = ` LIMIT ${carpetsPerPage} OFFSET ${offset}`;
      queryStr += tmp;

      const stmt = db.exec(queryStr);

      if (stmt.length === 0) {
        setqueryResult(null);
        setLoading(true);
        setglobalQuery(
          "SELECT Roll_Number, Category, Size, Primary_Colour, Length, Width, Price, Location, GST, Image_url, Colour, Updated_at FROM\
        carpet_info WHERE Category IS NOT NULL AND Category <> '' ORDER BY CAST(Roll_Number as int) DESC"
        );
      } else {
        const result = stmt[0].values;
        // console.log("result", result);
        setLoading(false);
        const formattedData = result.map((item, index) => (
          <Card
            key={item[0]}
            img={item[9]}
            title={item[0]}
            color={item[10].split(", ")[0]}
            colors={item[3]
              .split("#")
              .slice(1)
              .map((i) => "#" + i)}
            size={item[2]}
            category={item[1]}
            length={item[4]}
            breadth={item[5]}
            price={item[6]}
            location={item[7]}
            gst={item[8]}
            isSelected={selectedCards.includes(item[0])}
            toggleSelectCard={toggleSelectCard}
            interactive={true}
            updatedAt={item[11]}
          />
        ));
        setqueryResult(formattedData);
      }
    }
  };

  //add a new loaction directly from db
  useEffect(() => {
    const fetchLocation = async () => {
      if (dbInitialized) {
        try {
          const resultLocation = db.exec(queryLocation);

          if (resultLocation.length > 0) {
            const distinctLocations = resultLocation[0].values.map(
              (row) => row[0]
            );
            setAddLocation(distinctLocations);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchLocation();
  }, [dbInitialized, db]);

  return (
    <>
      <Sidebar
        handleChange={handleChange}
        expanded={expanded}
        setExpanded={setExpanded}
        onMinPriceChange={handleMinPriceChange}
        onMaxPriceChange={handleMaxPriceChange}
        minPrice={minPrice}
        maxPrice={maxPrice}
        dbMaxPrice={dbMaxPrice}
        dbMinPrice={dbMinPrice}
        selectedFilters={selectedFilters}
        handlePageChange={handlePageChange}
        clearAllFilters={clearAllFilters}
        filtersApplied={filtersApplied}
        addLocation={addLocation}
        handleCopyLink={handleCopyLink}
      />
      {!isNavbarVisible && (
        <Nav
          query={searchQuery}
          handleInputChange={handleInputChange}
          expanded={expanded}
          setExpanded={setExpanded}
          setQuery={setSearchQuery}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
      {isPdfHeaderVisible && <PdfHeader />}
      <Products
        queryResult={queryResult}
        expanded={expanded}
        setExpanded={setExpanded}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        carpetsPerPage={carpetsPerPage}
        totalCount={totalCount}
        loading={loading}
        setCurrentPage={setCurrentPage}
        newOffset={newOffset}
        selectedCards={selectedCards}
        transferCards={transferCards}
        toggleSelectCard={toggleSelectCard}
        filtersApplied={filtersApplied}
        handleCopyLink={handleCopyLink}
      />

      {loading && <Loading clearAllFilters={clearAllFilters} />}

      <Footer />
    </>
  );
}

export default App;
