// Card.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./card.css";
import { CirclePicker } from "react-color";
import { formatDistanceToNow } from "date-fns";
import { Watermark } from "antd";
import watermark from "../assets/watermark.png"

function Card({
  img,
  title,
  size,
  color,
  category,
  length,
  breadth,
  colors,
  price,
  gst,
  location,
  isSelected,
  toggleSelectCard,
  interactive,

  updatedAt,
}) {
  const navigate = useNavigate();
  const [isCopiedLinkPage, setIsCopiedLinkPage] = useState(false);

  useEffect(() => {
    // Check if the current page is the copied link page by checking the URL parameter
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("linkCopied") === btoa("true")) {
      setIsCopiedLinkPage(true);
    }
  }, []);

  const handleCardClick = () => {
    if (interactive) {
      const queryParams = new URLSearchParams({
        img: btoa(img),
        length: length,
        breadth: breadth,
        title: title,
      });
      navigate(`/view3d?${queryParams.toString()}`);
    }
  };

  const formatIndianNumber = (number) => {
    const formattedNumber = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(number);

    const formattedPrice = formattedNumber
      .replace(/^(\D+)/, "$1")
      .split(".")[0];

    return formattedPrice;
  };

  const formattedPrice = formatIndianNumber(price);

  const convertMetersToFeetAndInches = (meters) => {
    const METERS_PER_FOOT = 0.3048;
    const INCHES_PER_FOOT = 12.0;
    const heightInFeet = meters / METERS_PER_FOOT;
    let feet = Math.floor(heightInFeet);
    let inches = Math.round((heightInFeet - feet) * INCHES_PER_FOOT);

    if (inches >= INCHES_PER_FOOT) {
      feet = feet + 1;
      inches = inches - INCHES_PER_FOOT;
    }

    return ` ${feet}' ${inches}"`;
  };

  const lengthInFeet = convertMetersToFeetAndInches(length);

  const breadthInFeet = convertMetersToFeetAndInches(breadth);

  //add Watermark

  return (
    <section className="card w-64 m-4 p-3 flex justify-center items-center relative">
      {interactive && (
        <input
          type="checkbox"
          checked={isSelected}
          onChange={(e) => {
            e.stopPropagation();
            toggleSelectCard(title);
          }}
          className="absolute top-2 right-2 h-5 w-5 cursor-pointer"
        />
      )}
      <div className="cardContent">
        {isCopiedLinkPage ? (
          <Watermark
            offset={[25, 25]}
            rotate={[-30]}
            zIndex={10}
            image= {watermark}
            width={275}
            height={100}
            
          >
            <img src={img} alt={title} className="cardImg h-3/4" />
          </Watermark>
        ) : (
          <img src={img} alt={title} className="cardImg h-3/4" />
        )}

        <div
          className="cardDetails flex flex-col justify-center items-center cursor-pointer"
          onClick={handleCardClick}
        >
          <h4 className="cardTitle w-full flex items-center justify-center pt-4 font-semibold">
            {title}
          </h4>
          <div className="flex pt-3">
            <h5 className="text-[#000] font-semibold">{formattedPrice}</h5>
            <p className="cardSize text-sm pl-1">( excl. {gst * 100}% GST )</p>
          </div>
          <div className="cardCategory pt-3">
            Category: <span className="text-[#000]">{category}</span>
          </div>
          <div className="cardDim pt-3">
            Dimensions:{" "}
            <span className="text-[#000]">{`${breadthInFeet} x ${lengthInFeet}`}</span>
          </div>
          <div className="cardDim pt-3">
            Location: <span className="text-[#000]">{location}</span>
          </div>
          <div className="cardSize pt-3">
            Size: <span className="text-[#000]">{size}</span>
          </div>
          <p className="cardColor pt-3">
            Color: <span className="text-[#000]">{color}</span>
          </p>
          <div className="w-full pt-3">
            <CirclePicker
              colors={colors}
              circleSize={20}
              className="flex justify-center items-center"
            />
          </div>
        </div>
        <div className="flex justify-end items-end mt-4">
          <p className="text-[0.6rem]">
            *updated {formatDistanceToNow(updatedAt, { addSuffix: true })}
          </p>
        </div>
      </div>
    </section>
  );
}

export default Card;
