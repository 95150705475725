import React from "react";
import "./Sidebar.css";
import Category from "./category/Category.js";
import Size from "./Size/Size.js";
import Colors from "./colors/Colors.js";
import { IoIosCloseCircleOutline } from "react-icons/io";
import PriceFilter from "../Sidebar/price/PriceFilter.js";
import Location from "./location/Location.js";

function Sidebar({
  handleChange,
  expanded,
  setExpanded,
  onMinPriceChange,
  onMaxPriceChange,
  minPrice,
  maxPrice,
  selectedFilters,
  dbMinPrice,
  dbMaxPrice,
  handlePageChange,
  clearAllFilters,
  filtersApplied,
  addLocation,
  handleCopyLink
}) {
  const toggleNav = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  return (
    <>
      <section
        className={`sidebar h-screen flex flex-col z-50 fixed overflow-y-scroll ${
          expanded
            ? "w-72 border-r-4 pl-0 pr-4 lg:pr-0 lg:pl-8 border-{#e5e5e5} bg-[#fafafa]"
            : "w-0"
        } `}
      >
        <IoIosCloseCircleOutline
          onClick={toggleNav}
          className={"h-10 w-10 left-3 translate-y-16 cursor-pointer absolute"}
        />

        <div
          className={`mt-16 text-3xl mb-8 w-full lg:w-52 ${
            expanded ? "w-4/5" : "w-0"
          }`}
        >
          <h1 className="text-[#EC1C24] flex items-center justify-center">
            FILTERS
          </h1>

          {filtersApplied && (
            <button
              onClick={clearAllFilters}
              className="text-[#EC1C24] border-2 text-[1rem] p-1 ml-14 mt-8 right-0 rounded-md border-red-500 flex items-center justify-center"
            >
              CLEAR ALL
            </button>
          )}
        </div>


        <Category
          handleChange={handleChange}
          expanded={expanded}
          selectedFilters={selectedFilters}
          handlePageChange={handlePageChange}
        />

        <Size
          handleChange={handleChange}
          expanded={expanded}
          selectedFilters={selectedFilters}
        />
        <Location
          handleChange={handleChange}
          expanded={expanded}
          selectedFilters={selectedFilters}
          addLocation={addLocation}
        />
        <PriceFilter
          minPrice={minPrice}
          maxPrice={maxPrice}
          onMinPriceChange={onMinPriceChange}
          onMaxPriceChange={onMaxPriceChange}
          expanded={expanded}
          dbMaxPrice={dbMaxPrice}
          dbMinPrice={dbMinPrice}
        />
        <Colors
          handleChange={handleChange}
          expanded={expanded}
          selectedFilters={selectedFilters}
        />
      </section>
    </>
  );
}

export default Sidebar;
