import "./Products.css";
import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { IoIosArrowUp } from "react-icons/io";

function Products({
  queryResult,
  expanded,
  carpetsPerPage,
  totalCount,
  onPageChange,
  currentPage,
  loading,
  selectedCards,
  transferCards,
  toggleSelectCard,
  filtersApplied,
  handleCopyLink,
}) {
  const [scroll, setScroll] = useState(false);
  const [isCopiedLinkPage, setIsCopiedLinkPage] = useState(false);

  useEffect(() => {
    // Check if the current page is the copied link page by checking the URL parameter
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("linkCopied") === btoa("true")) {
      setIsCopiedLinkPage(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  }, [setScroll, scroll]);

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageClick = (event, page) => {
    onPageChange(page);
  };

  return (
    <div className="relative">
      {expanded ? (
        <>
          {!isCopiedLinkPage && (
            <div className="flex flex-row items-center justify-end mr-4 left-[80vw] lg:left-[90vw]">
              {filtersApplied && (
                <button
                  onClick={handleCopyLink}
                  disabled={!filtersApplied}
                  className={` sm:mt-48 lg:mt-36 relative border-2 p-1 rounded-md w-24 ${
                    filtersApplied
                      ? "text-[#d84d4d] border-red-500"
                      : "disabled-button"
                  }`}
                >
                  Copy Link
                </button>
              )}

              <button
                onClick={transferCards}
                disabled={selectedCards.length === 0}
                className={`ml-2 sm:mt-48 lg:mt-36 relative border-2 p-1 rounded-md w-16 ${
                  selectedCards.length === 0
                    ? "disabled-button"
                    : "text-[#d84d4d] border-red-500"
                }`}
              >
                Export
              </button>
            </div>
          )}

          {isCopiedLinkPage ? (
            <section className="cardContainer flex flex-wrap justify-center">
              {queryResult}
            </section>
          ) : (
            <section className="cardContainer flex flex-wrap justify-center">
              {queryResult}
            </section>
          )}
          {scroll && (
            <IoIosArrowUp
              onClick={scrollUp}
              className=" fixed right-10 h-10 w-10 text-[#d84d4d] bottom-40 border-b-2 cursor-pointer bg-[#c6bfbf]"
            />
          )}
          {!loading && scroll && (
            <Stack
              spacing={2}
              className="flex justify-center items-center mb-32 w-68 mt-12"
            >
              <Pagination
                count={Math.ceil(totalCount / carpetsPerPage)}
                onChange={handlePageClick}
                page={currentPage}
              />
            </Stack>
          )}
        </>
      ) : (
        <div className="pt-48 sm:pt-0">
          {!isCopiedLinkPage && (
            <div className="flex flex-row items-center justify-end mr-4 left-[80vw] lg:left-[90vw]">
              {filtersApplied && (
                <button
                  onClick={handleCopyLink}
                  disabled={!filtersApplied && isCopiedLinkPage}
                  className={` sm:mt-48 lg:mt-36 relative border-2 p-1 rounded-md w-24 ${
                    filtersApplied 
                      ? "text-[#d84d4d] border-red-500"
                      : "disabled-button"
                  }`}
                >
                  Copy Link
                </button>
              )}

              <button
                onClick={transferCards}
                disabled={selectedCards.length === 0}
                className={`ml-2 sm:mt-48 lg:mt-36 relative border-2 p-1 rounded-md w-16 ${
                  selectedCards.length === 0
                    ? "disabled-button"
                    : "text-[#d84d4d] border-red-500"
                }`}
              >
                Export
              </button>
            </div>
          )}

          {isCopiedLinkPage ? (
            <section className="cardContainer flex flex-wrap justify-center pt-0 sm:translate-y-36 ">
              {queryResult}
            </section>
          ) : (
            <section className="cardContainer flex flex-wrap justify-center">
              {queryResult}
            </section>
          )}
          {scroll && (
            <IoIosArrowUp
              onClick={scrollUp}
              className=" fixed right-10 h-10 w-10 text-[#d84d4d] bottom-40 border-b-2 cursor-pointer bg-[#c6bfbf]"
            />
          )}
          {!loading && scroll && (
            <Stack
              spacing={2}
              className={`flex justify-center items-center mb-32 w-68  ${isCopiedLinkPage === true ? "mt-12 sm:mt-36" : "mt-12"} `}
            >
              <Pagination
                count={Math.ceil(totalCount / carpetsPerPage)}
                onChange={handlePageClick}
                page={currentPage}
              />
            </Stack>
          )}
        </div>
      )}
    </div>
  );
}

export default Products;
